import React from "react";
import styled from "styled-components";
import { routeHelper } from "../../../utils/routeHelper";
import { Themes } from "../../models/themes";

const CTAButton = ({ title, href, theme }) => {
  return (
    <ButtonWrapper
      href={`${routeHelper(href)}`}
      colorMode={theme}
      className="d-flex flex-row align-items-center justify-content-center"
    >
      <ButtonText colorMode={theme}>{title}</ButtonText>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.a`
  margin: 0 auto;
  max-width: 220px;
  white-space: nowrap;
  border-radius: 4px;
  padding: 10px 15px 11px 11px;
  margin-top: 48px;
  @media only screen and (max-width: 1200px) {
    margin-top: 24px;
  }

  border: 1px solid ${({ theme }) => theme.colors.buttonBorder};
  text-decoration: none;

  &:not(:last-of-type) {
    margin-right: 24px;
    @media only screen and (max-width: 1200px) {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }

  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.darkGreen:
      case Themes.image:
      case Themes.green:
        return "white";
      case Themes.lightGreen:
      case Themes.white:
        return theme.colors.navyPrimary;
    }
  }};
  :hover {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.darkGreen:
        case Themes.image:
        case Themes.green:
          return theme.colors.lightButtonHover;
        case Themes.lightGreen:
        case Themes.white:
          return theme.colors.navyBackgroundHover;
      }
    }};
  }
  :active {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.lightGreen:
        case Themes.white:
          return theme.colors.navyBackgroundPressed;
        case Themes.darkGreen:
        case Themes.image:
        case Themes.green:
          return theme.colors.lightButtonPressed;
      }
    }};
  }
`;

const ButtonText = styled.p`
  text-align: center;
  width: 100%;
  font-family: Agenda-Semibold;
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.lightGreen:
      case Themes.white:
        return "white";
      case Themes.darkGreen:
      case Themes.image:
      case Themes.green:
        return theme.colors.navyPrimary;
    }
  }};
  :active {
    color: white;
  }
`;

export default CTAButton;
