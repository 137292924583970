import React from "react"

const DebaTriangleIcon = () => (
  <svg
    width="97px"
    height="81px"
    viewBox="0 0 97 81"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>B5D8675D-E286-43FE-B6A7-AE7C3D1D0E4D</title>
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(-1263.000000, -5111.000000)"
      >
        <g transform="translate(0.000000, 4834.000000)">
          <g transform="translate(1263.000000, 277.000000)">
            <polygon 
              fill="#24A866"
              fill-rule="nonzero"
              points="97 38 97 0 59 0"
            ></polygon>
            <polygon 
              fill="#E0EEE7"
              points="79 66 40 66 79 81"
            ></polygon>
            <polygon
              fill="#E0EEE7"
              points="26 45 0 45 26 55"
            ></polygon>
            <polygon
              fill="#24A866"
              fill-rule="nonzero"
              points="82 78 82 40 44 40"
            ></polygon>
            <polygon
              fill="#24A866"
              fill-rule="nonzero"
              points="28 52 28 27 3 27"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DebaTriangleIcon
