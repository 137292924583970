import React from "react";
import styled from "styled-components";
import RightIcon from "../../components/icons/RightIcon";
import { handleCTALink } from "../../components/layouts/components/CallToActionH2";
import { routeHelper } from "../../utils/routeHelper";

const handleCTADestructure = (cta) => {
  if (cta?.cta) {
    return {
      image: cta?.cta?.call_to_action_image?.url,
      title: cta?.cta?.call_to_action_title
        ? cta?.cta?.call_to_action_title[0]?.text
        : "",
      subtitle: cta?.cta?.call_to_action_text
        ? cta?.cta?.call_to_action_text[0]?.text
        : "",
      link: cta?.cta?.call_to_action_link?._meta?.uid
        ? cta?.cta?.call_to_action_link?._meta?.uid.replace(/__/g, "/")
        : cta?.cta?.call_to_action_link?.url,
    };
  } else if (cta?.primary) {
    return {
      image: cta?.primary.image?.url,
      title: cta?.primary?.title1 ? cta?.primary?.title1[0]?.text : "",
      subtitle: cta?.primary?.subtitle ? cta?.primary?.subtitle[0]?.text : "",
      link: cta?.primary.link?._meta?.uid
        ? cta?.primary.link?._meta?.uid.replace(/__/g, "/")
        : cta?.primary.link?.url,
    };
  } else return null;
};

const CallToAction = (primary) => {
  const cta = handleCTADestructure({ ...primary });
  if (!cta) return null;
  return (
    <CallToActionLink href={`${routeHelper(handleCTALink(cta.link))}`}>
      <CallToActionWrapper className="d-flex justify-content-center flex-row">
        <ContentWrapper className="d-flex flex-column flex-md-row  align-items-center">
          <CTAImage src={cta.image} width="141px" height="100%" className="mb-2 mb-md-0" />
          <div className="ms-3">
            <CTATitle>{cta.title}</CTATitle>
            <CTASubtitle>{cta.subtitle}</CTASubtitle>            
            <RightIcon />
          </div>
        </ContentWrapper>
      </CallToActionWrapper>
    </CallToActionLink>
  );
};

const ContentWrapper = styled.div`
  @media only screen and (max-width: 1200px) {
    flex-direction: row-reverse;
  }
`;

export const CallToActionWrapper = styled.div`
  padding: 27px 0;
  background-color: ${({ theme }) => theme.colors.greenTransparent};
  font-family: Agenda;
  font-size: 31px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  cursor: pointer;

  div {
    max-width: 1000px;
  }
`;

const CallToActionLink = styled.a`
  text-decoration: none;
`;

const CTAImage = styled.img`
  max-height: 160px;
  width: auto;
  height: auto;

  @media only screen and (max-width: 1200px) {
    padding-left: 24px;
  }
`;

const CTASubtitle = styled.h2`
  font-family: Agenda-Semibold;
  padding-bottom: 8px;
  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const CTATitle = styled.h2`
  opacity: 0.6;
  padding-bottom: 8px;

  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export default CallToAction;
