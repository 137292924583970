const headingMapping = {
    heading1: "h1",
    heading2: "h2",
    heading3: "h3",
    heading4: "h4",
    heading5: "h5",
    heading6: "h6",
  };

export function mapPrismicHeaderToTag(header) {
    return headingMapping[header];
}