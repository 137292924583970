import React from "react"

const RightIcon = () => (
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>6E659EFB-0051-4DC5-936E-64225DBD7C43</title>
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        transform="translate(-549.000000, -9600.000000)"
        fill="#00333D"
      >
        <g transform="translate(0.000000, 9479.084324)">
          <g transform="translate(386.000000, 27.000000)">
            <g transform="translate(163.000000, 10.000000)">
              <g transform="translate(0.000000, 84.000000)">
                <polygon 
                  points="13.3 5.3 11.9 6.7 16.2 11 4 11 4 13 16.2 13 11.9 17.3 13.3 18.7 20 12"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default RightIcon
